.movie {
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid #fff;
  padding: 1em;
  min-width: 333px;
  max-width: 333px;
}
