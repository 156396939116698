@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
div {
  font-family: 'Roboto', sans-serif;
}

input,
label {
  color: white !important;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
