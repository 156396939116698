@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.Movie_movie__1GiYA {
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid #fff;
  padding: 1em;
  min-width: 333px;
  max-width: 333px;
}

button[type=submit].Mui-disabled {
  background-color: #959595 !important;
  color: #000 !important;
  -webkit-transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 5000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 5000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

button {
  width: 212px;
}

button[data-color=colorful] {
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
}

#Form_keyword__3n-K0 {
  cursor: pointer;
}

.movies {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  grid-gap: 16px;
}

@media (min-width: 768px) {
  .movies {
    grid-template-columns: auto auto;
  }
}

@media (min-width: 1024px) {
  .movies {
    grid-template-columns: auto auto auto;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
div {
  font-family: 'Roboto', sans-serif;
}

input,
label {
  color: white !important;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

