.movies {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  grid-gap: 16px;
}

@media (min-width: 768px) {
  .movies {
    grid-template-columns: auto auto;
  }
}

@media (min-width: 1024px) {
  .movies {
    grid-template-columns: auto auto auto;
  }
}
