button[type=submit].Mui-disabled {
  background-color: #959595 !important;
  color: #000 !important;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 5000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

button {
  width: 212px;
}

button[data-color=colorful] {
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
}
